import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import LazyLoadDirective from './directives/LazyLoadDirective'


import ApiService from './services/ApiService';

import CKEditor from '@ckeditor/ckeditor5-vue';

import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
const app = createApp(App);


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


ApiService.init(app, store);

app.directive("lazyload", LazyLoadDirective);

app.use(VueVideoPlayer);
app.use(store);
app.use(CKEditor);
app.use(VNetworkGraph)
app.mixin({
    methods: {
        uuidv4: function () {
            let uuid = ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );

            return 'i' + uuid;
        }
    }
})
app.use(VueSweetalert2);
app.use(router);
app.use(ElementPlus);
app.mount('#app')
