<template>
  <div class="flex">
    <el-upload :on-change="onFileUploadError" name="video" :on-error="onFileUploadError" :on-progress="onFileUploading"
      :on-success="onSuccessfullyUploaded" class="upload-demo  hidden" action="https://node.incore.space/upload/video">
      <button class="videoUploadMain"></button>
    </el-upload>
    <Transition enter-active-class="animate__animated animate__bounceInDown"
      leave-active-class="animate__animated animate__bounceOutRight">
      <div v-if="getCurrentUpload && uploadingNow"
        class="absolute flex top-2 right-2 bg-green-400 h-12 w-72 rounded z-[1000]">
        <div class="flex gap-2 w-full items-center justify-center ">
          <el-icon>
            <InfoFilled class="text-white" />
          </el-icon>
          <span class="font-poppins text-sm font-medium text-center   text-white"> Video Yükleniyor...</span>
        </div>
        <div class="absolute bottom-0 left-0 bg-green-200 h-2 " :style="{ width: getCurrentUpload.progress + '%' }">
        </div>
      </div>
    </Transition>
    <router-view />
  </div>
</template>


<script>
import { computed, ref } from 'vue';
import store from '@/store';
import { InfoFilled } from '@element-plus/icons-vue'
export default {
  components: {
    InfoFilled
  },
  setup() {

    const uploadingNow = ref(false);
    const onSuccessfullyUploaded = (e) => {
      console.log(e);
      // getChoosenSingleItem.value.src = e.data;

      getCurrentUpload.value.element.src = e.data;
      store.commit('setCurrentUpload', null);
      uploadingNow.value = false
    }

    const onFileUploadError = (e) => {
      console.log("ERRORRRRRR ÇALIŞTIII:", e);
    }


    const getCurrentUpload = computed(() => {
      return store.getters.getCurrentUpload;
    });

    const onFileUploading = (e) => {
      uploadingNow.value = true;
      store.commit('setUploadProgress', e.percent);
      console.log("ELEMAN YÜKLENİYOR:", e)
    }
    return {
      onSuccessfullyUploaded,
      getCurrentUpload,
      onFileUploading,
      uploadingNow,
      onFileUploadError
    }
  }

}

</script>
<style>
@import "https://pagecdn.io/lib/easyfonts/fonts.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
@import "@/assets/css/fonts.css";

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alfa+Slab+One&family=Bebas+Neue&family=Caveat:wght@400;700&family=Comfortaa:wght@400;700&family=Dancing+Script:wght@400;700&family=IBM+Plex+Mono:wght@400;700&family=Inconsolata:wght@400;700&family=Indie+Flower&family=Lato:wght@400;700&family=Lobster&family=Lora:wght@400;700&family=Merriweather:wght@400;700&family=Montserrat:wght@400;700&family=Nunito:wght@400;700&family=Open+Sans:wght@400;700&family=PT+Serif:wght@400;700&family=Pacifico&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;700&family=Roboto+Mono:wght@400;700&family=Roboto+Slab:wght@400;700&family=Shadows+Into+Light&family=Source+Code+Pro:wght@400;700&family=Space+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

#app {
  background: white;
}

a {
  list-style: none;

}

button {
  outline: unset;
  border: unset;
  cursor: pointer;
}

.el-input__inner {
  font-family: 'poppins';
}

.el-input__inner::placeholder {
  font-family: 'poppins';

}

.video-js .vjs-big-play-button {
  inset: 0;
  margin: auto;
}
</style>